import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BlogSlider,
  BannerLinks,
  Breadcrumbs,
  Image
} from '../../components'

import BannerImage from '../../assets/header.userfirst.jpg'
import googledocs from '../../assets/googledocs.png'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import { generateBreadcrumbsLinks } from '../../utils/index'

const UserFirst = () => {
  const links = generateBreadcrumbsLinks('/blog/user-first', 'User first')
  return (
    <Layout>
      <Helmet
        title="User first!"
        meta={[
          {
            name: 'description',
            content:
              'In unseren Projekten stellen wir Ihre Kunden an erste Stelle. Welche Bedürfnisse haben sie, in welchem Kontext bewegen sie sich? Erst dann wird entschieden, ob ein responsives oder adaptives Design von Vorteil ist.'
          },
          {
            name: 'keywords',
            content:
              'user first, user-first, mobile first, customer first, Zielgruppe, User, Smartphones, Ausgabegerät, holzweg, customer journey, story mapping, ERM, empathy map'
          },
          {
            property: 'og:description',
            content:
              'In unseren Projekten stellen wir Ihre Kunden an erste Stelle. Welche Bedürfnisse haben sie, in welchem Kontext bewegen sie sich? Erst dann wird entschieden, ob ein responsives oder adaptives Design von Vorteil ist.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.userfirst-f64f2da4acd9cad62aa9484a883e7b3e.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            User -
            <br />
            wer bist Du?
          </span>
        }
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title={
          <span><h2>Mobile-first<br />Desktop-worst</h2></span>
        }
        text={
          <span><p>Noch vor ein paar Jahren waren es nur wenige Browser und unterschiedliche Betriebssysteme mit einigen, aber überschaubaren Bildschirmgrößen für Desktop Computer, die uns Webdesigner nachts nicht schlafen oder vielleicht gerade deshalb gut schlafen ließen. <br />Rasend schnell stehen wir 2019 aber vor einer schier <b>unendlichen Anzahl an möglichen Ausgabegeräten</b>: Smartphones, Phablets, Smartwatches, Tablets, Netbooks, Notebooks, Smart TVs, Konsolen, Braillezeilen, Holo-Lenses, Voice-Services. Multipliziert man diese mit <b>allen Browservarianten und Auflösungen</b>, entwickelt sich das zu einem Webdesigner-Alptraum.</p><p>Nun haben sich kreative Köpfe gedacht, lasst uns nur mehr für mobile Endgeräte entwickeln (<b>mobile first</b>)! Der Schuss geht aber nach hinten los, sofern sie User haben, die auch auf dem Desktop surfen: versteckte Navigationspunkte hinter drei Linien (Hamburger Menü), Such-Icon ohne Suchbox - obwohl genügend Platz vorhanden ist, und leere Desktop-Websites, die vielleicht auf den ersten Blick vornehme Schlichtheit suggerieren, aber bald eine gähnende Leere hinterlassen, machen die Desktop-Erfahrung zunichte. </p><br /><h4>Reduzierte Darstellung ist nun mal nicht gleichzusetzen mit besserer User-Experience.</h4></span>
        }
      />
      <Image
        image={googledocs}
        title="Google versteckt die Navigation trotz ausreichendem Platz am Desktop."
      />
      <TextBox
        title={
          <span><h3>Und in Zukunft? <br />Watch-first, Augmented-first, <nobr>Voice-first?</nobr></h3></span>
        }
        text={
          <span><p><b>Unser Ansatz:</b> wir sollten uns wieder auf die Kunden unser Website/App besinnen und zuerst feststellen, was will der User/Kunde erreichen und über welche Ausgabegeräte möchte er welche Aufgaben erledigen? Wenn nun 99% der Kunden auf einem mobilen Gerät ein Tagebuch führen möchten, dann entwickeln wir mit Fokus auf mobil. Aber es kommt eben auf den Kontext an! Und wie findet man das alles heraus?</p><h3>Evergreen: Zielgruppe!</h3><p>Mit User Interviews, Customer Journeys, Story Mapping, Empathy Maps, Personas und Entity Relationship Modellen konzentrieren wir uns auf die Zielgruppen. Wer sind die User und wieviele? Was denken/hören/sehen und fühlen sie? Was sind ihre Bedürfnisse? Welche Wege durchlaufen sie auf ihrer Journey? Welches Verhalten legen sie an den Tag und in welchem Kontext bewegen sie sich? Gepaart mit einer Analyse der Ausgabegeräte kann sich eine Priorisierung auf eine Gruppe an Geräten, Browser und Bildschirmgrößen ergeben. Die Website/App anschließend <b>adaptiv</b> an diese anzupassen führt zu besserer Performance und hoher Kundenzufriedenheit!</p></span>
        }
      />
      <TextBox titleMods={['center']} title="Das könnte Sie auch interessieren:" text="" />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default UserFirst
